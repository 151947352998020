import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ContentActions } from "../state/ContentState";
import ItemList from "../components/ItemList";
import LoadingSection from "../components/LoadingSection";
import { NavigationCopy } from "../state/navigation_copy";
import { useHistory } from "react-router-dom";

function renderSeriesList(series) {
  const seriesForList = Object.entries(series).map(([_, s]) => {
    return {
      id: s.id,
      title: s.display_title,
      summary: s.description,
      href: "/series/" + s.id.toString(),
    };
  });

  return (
    <section className="section series-page">
      <div className="columns is-centered pageHeader">
        <div className="column has-text-centered">
          <h1 className="app-brand">{NavigationCopy.publicationTitle}</h1>
          <h2>{NavigationCopy.seriesCallToAction}</h2>
        </div>
      </div>

      <ItemList items={seriesForList} />
    </section>
  );
}

function SeriesPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const series = useSelector((state) => state.content.series);

  useEffect(() => {
    if (!series) {
      dispatch(ContentActions.getContent());
    }
  });

  if (!series) {
    return <LoadingSection></LoadingSection>;
  }

  const seriesKeys = Object.keys(series);

  if (seriesKeys.length === 1) {
    const mainSeriesID = Object.keys(series)[0];
    history.replace(`/series/${mainSeriesID}`);
  }

  return renderSeriesList(series);
}

export default SeriesPage;
