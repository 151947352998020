import React from 'react'
import { useState } from "react";
import { Link } from 'react-router-dom';

export default function HamburgerNav(props) {
  const [isOpen, setIsOpen] = useState(false)


  let navBarMenuClass = 'navbar-menu'
  if (isOpen) {
    navBarMenuClass += ' is-active'
  }

  const renderPageLinks = () => {
    return props.pages.map((page) => {
      return (
        <div key={ Math.random() } className="navbar-item">
          <Link to={page.href} onClick={() => { setIsOpen(!isOpen) }}>
            {page.title}
          </Link>
        </div>
      )
    })
  }

  return (
    <nav className="navbar has-shadow">
      <div className="navbar-brand">
        <div className="navbar-burger" onClick={() => { setIsOpen(!isOpen) }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className={navBarMenuClass}>

        {renderPageLinks()}

      </div>
    </nav>
  )

}