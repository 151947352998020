import React from 'react'
import { Link } from 'react-router-dom'

function SlideShowListItem({ slideShow }) {
  if (!slideShow.preview_image_url){
    return null;
  }
  return (
    <div key={slideShow.id} className="column">
      <Link to={slideShow.href}>
        <div className="item card clickable-card slide-show-item">
          <div className="card-content">
            <img src={slideShow.preview_image_url} alt={slideShow.title} className='slide-show-preview'></img>
          </div>
        </div>
      </Link>
    </div>
  )

}

export default SlideShowListItem
