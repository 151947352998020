import { createReducer, createAction } from "redux-starter-kit";
import axios from "axios";
import { API_BASE } from "../utils/config";

const randomId = () => {
  return Math.floor(Math.random() * 10000000000);
};

const trackingUrl = () => {
  return API_BASE + "/track";
};

const trackPageView = (page) => {
  return async (dispatch, getState) => {
    const currentUser =
      getState().analytics.userId || localStorage.getItem("user_id");
    if (!currentUser) {
      await dispatch(setUser());
      return dispatch(trackPageView(page));
    }

    axios.post(trackingUrl(), { userID: currentUser, page: page });
  };
};

const setUser = () => {
  return async (dispatch) => {
    const userId = randomId();
    localStorage.setItem("user_id", userId);
    return dispatch(AnalyticsActions.setUserSuccess(userId));
  };
};

export const AnalyticsActions = {
  setUser: setUser,
  trackPageView: trackPageView,
  setUserSuccess: createAction("SET_USER_SUCCESS"),
};

export const analyticsReducer = createReducer(
  {},
  {
    [AnalyticsActions.setUserSuccess]: (state, action) => {
      state.userId = action.payload;
    },
  }
);
