import { spanishLanguageMode, isMeAfterWe } from "../utils/config";

const spanishLanguageCopy = {
  navItems: {
    home: "Inicio",
    resources: "Recursos",
  },
  publicationTitle: "APOYANDO SU SALUD MENTAL DURANTE EL CORONAVIRUS",
  seriesCallToAction: "¿En qué le gustaría enfocarse?",
};

const englishLanguageCopy = {
  navItems: {
    home: "Home",
    resources: "Resources",
  },
  publicationTitle: "SUPPORTING YOUR MENTAL HEALTH DURING CORONAVIRUS",
  seriesCallToAction: "What would you like to focus on?",
};

const meAfterWeCopy = {
  navItems: {
    home: "Home",
    resources: "Resources",
  },
  publicationTitle: "Your 30 Day Breakup Healing Guide",
  seriesCallToAction: "Your 30 Day Breakup Healing Guide",
};

function languageSpecificCopy() {
  if (spanishLanguageMode()) {
    return spanishLanguageCopy;
  }

  if (isMeAfterWe()) {
    return meAfterWeCopy;
  }

  return englishLanguageCopy;
}

export const NavigationCopy = languageSpecificCopy();
