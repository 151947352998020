function getApiBaseUrl() {
  if (process.env.NODE_ENV === "production") {
    return "https://app.allmentalhealth.org/api";
  }

  return "http://localhost:3001/api";
}

export function spanishLanguageMode() {
  const spanishHostname = window.location.hostname.match("-es");
  const spanishBrowserLanguage = window.navigator.language === "es";

  return spanishHostname || spanishBrowserLanguage;
}

export const isMeAfterWe = () => {
  return (
    !!window.location.hostname.match("meafterwe") ||
    !!window.location.hostname.match("maw")
  );
};

function getPublicationID() {
  if (spanishLanguageMode()) {
    return process.env.REACT_APP_SPANISH_PUBLICATION_ID;
  }

  console.log("Is me after we == ", isMeAfterWe());

  if (isMeAfterWe()) {
    return process.env.REACT_APP_MAW_PUBLICATION_ID;
  }

  return process.env.REACT_APP_PUBLICATION_ID || "";
}

export const API_BASE = getApiBaseUrl();
export const PUBLICATION_ID = getPublicationID();
