import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import "./App.sass";
import SeriesPage from "./containers/SeriesPage";
import ArticlePage from "./containers/ArticlePage";
import SeriesArticlesPage from "./containers/SeriesArticlesPage";

import { store } from "./state/store";
import ScrollToTopOnNavigation from "./components/ScrollToTopOnNavigation";
import LanguageSpecificFooter from "./components/LanguageSpecificFooter";
import HamburgerNav from "./components/HamburgerNav";
import ResourcesPage from "./containers/ResourcesPage";
import TrackPageView from "./components/TrackPageView";
import SlideShowPage from "./containers/SlideShowPage";
import { NavigationCopy } from "./state/navigation_copy";
import OpenGraphMetaData from "./components/OpenGraphMetaData";

function NotFoundPage() {
  return <h1>We could not find what you were looking for</h1>;
}

function App() {
  const renderRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={SeriesPage} />
        <Route exact path="/resources" component={ResourcesPage} />
        <Route exact path="/series/:seriesID" component={SeriesArticlesPage} />
        <Route exact path="/articles/:articleID" component={ArticlePage} />
        <Route exact path="/slideshow/:slideShowID" component={SlideShowPage} />
        <Route exact path="/404" component={NotFoundPage} />
      </Switch>
    );
  };

  const navPages = [
    { href: "/", title: NavigationCopy.navItems.home },
    { href: "/resources", title: NavigationCopy.navItems.resources },
  ];

  const supportsHistory = "pushState" in window.history;

  return (
    <Provider store={store}>
      <Router forceRefresh={!supportsHistory}>
        <TrackPageView></TrackPageView>
        <OpenGraphMetaData />

        <ScrollToTopOnNavigation></ScrollToTopOnNavigation>
        <div>
          <HamburgerNav pages={navPages}></HamburgerNav>

          <section className="app-body is-large">
            <div className="container">{renderRoutes()}</div>
          </section>
          <LanguageSpecificFooter />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
