
import { configureStore } from 'redux-starter-kit'

import { articlesReducer } from './ContentState'
import { analyticsReducer } from './Analytics'

export const store = configureStore({
  initialState: {},
  reducer: {
    content: articlesReducer,
    analytics: analyticsReducer,
  },
})
