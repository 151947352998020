import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { ContentActions } from '../state/ContentState'
import ItemList from '../components/ItemList'
import LoadingSection from '../components/LoadingSection'
import SlideShowListItem from '../components/SlideShowListItem'

function renderRedirect() {
  return(
    <Redirect to={'/404'} />
  )
}

function articlesToCardAttributes(articles) {
  return articles.map((article) => {
    return {
      id: article.id,
      title: article.title,
      summary: article.summary,
      href: '/articles/' + article.id.toString(),
    };
  });
}

function slideShowIdsToCardAttributes({ ids, slideShows }) {
  const seriesSlideShows = ids.map((slideShowID) => {
    const slideShow = slideShows[slideShowID]
    if (!slideShow) {
      return null
    }

    const href = '/slideshow/' + slideShow.id.toString();

    return Object.assign({href: href}, slideShow);
  })

  return seriesSlideShows.filter((s) => s !== null)
}

function itemsToDisplay({series, slideShows}) {
  if (!series.use_slide_shows) {
    return articlesToCardAttributes(series.articles);
  }

  return slideShowIdsToCardAttributes({ids: series.slide_show_ids, slideShows: slideShows});
}

function slideShowList(entries) {
  const listItems = entries.map((entry) => {
    return (
      <div key={entry.id} className='column is-one-third-widescreen is-half-desktop is-half-tablet'>
        <SlideShowListItem key={entry.id} slideShow={entry} />
      </div>
    );
  });

  return (
    < ItemList listEntryComponents={listItems} />
  );
}

function SeriesArticlesPage({ match }) {
  const seriesId = parseInt(match.params.seriesID)
  const allSeries = useSelector(state => state.content.series )
  const allSlideShows = useSelector(state => state.content.slideShows)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!allSeries) {
      dispatch(ContentActions.getContent());
    }
    dispatch(ContentActions.setCurrentSeries({seriesID: seriesId}));
  }, [dispatch, allSeries, seriesId])

  if (!seriesId) {
    return renderRedirect();
  }

  if (!allSeries) {
    return (
      <LoadingSection></LoadingSection>
    );
  }

  const desiredSeries = allSeries[seriesId];

  if (!desiredSeries) {
    return renderRedirect();
  }

  const entriesForList = itemsToDisplay({series: desiredSeries, slideShows: allSlideShows});

  return (
    <section className="section series-page">
      <div className="columns is-centered pageHeader">
        <div className="column has-text-centered">
          <h1>{desiredSeries.display_title}</h1>
        </div>
      </div>

      {
        desiredSeries.use_slide_shows ?
          slideShowList(entriesForList) :
          <ItemList items={entriesForList} />
      }
    </section>
  );
}

export default SeriesArticlesPage
