import React from 'react'

import Item from './Item'

function ItemList({ items, listEntryComponents }) {

  function renderItems() {
    return items.map((item, index) => {
      return (
        <div key={item.id} className="column is-one-third-widescreen is-half-desktop is-half-tablet">
          <Item item={item} itemIndex={index}/>
        </div>
      )
    })
  }

  return (
    <div className="columns is-multiline">
      {
        listEntryComponents ?
          listEntryComponents :
          renderItems()
      }
    </div>
  )

}

export default ItemList
