import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import { AnalyticsActions } from "../state/Analytics"
import { useDispatch } from "react-redux"

export default function TrackPageView() {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(AnalyticsActions.trackPageView(location.pathname))
  }, [dispatch, location])

  return null;
}