import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import { ContentActions } from "../state/ContentState";
import Stories from "react-insta-stories";
import { useWindowSize } from "../utils/useWindowSize";
import LoadingSection from "../components/LoadingSection";
import OpenGraphMetaData from "../components/OpenGraphMetaData";

function renderRedirect() {
  return <Redirect to={"/404"} />;
}

function SlideShowPage({ match }) {
  const slideShowId = parseInt(match.params.slideShowID);
  const slideShows = useSelector((state) => state.content.slideShows);
  const dispatch = useDispatch();
  const history = useHistory();

  const windowSize = useWindowSize();

  useEffect(() => {
    if (!slideShows) {
      dispatch(ContentActions.getContent());
    }
  }, [dispatch, slideShowId, slideShows]);

  if (!slideShowId) {
    return renderRedirect();
  }

  if (!slideShows) {
    return <LoadingSection></LoadingSection>;
  }

  const currentSlideShow = slideShows[slideShowId];

  console.log("rendering with current slide show", currentSlideShow);

  function sizeOfStory() {
    const maxHeight = 780;
    if (windowSize.height > maxHeight && windowSize.width > 439) {
      return {
        height: 780,
        width: 439,
      };
    }

    const maxWidth = windowSize.width - 40;

    const suggestedHeight = Math.floor(maxWidth * (16 / 9));
    const limitingHeight = windowSize.height - 20;

    if (suggestedHeight < limitingHeight) {
      return {
        height: suggestedHeight,
        width: maxWidth,
      };
    }

    return {
      height: limitingHeight,
      width: Math.floor(limitingHeight * (9 / 16)),
    };
  }

  console.log("History length == ", history.length);
  function closeModal() {
    if (history.length > 2) {
      return history.goBack();
    }
    return history.push("/");
  }

  return (
    <>
      <OpenGraphMetaData
        title={currentSlideShow.title}
        description={currentSlideShow.description}
        isArticle={true}
        image={currentSlideShow.preview_image_url}
      />
      <div className="story-lightbox">
        <span className="story-dismiss" onClick={closeModal}>
          &times;
        </span>

        <div className="story-wrapper">
          <Stories
            stories={currentSlideShow.images}
            height={sizeOfStory().height}
            width={sizeOfStory().width}
            defaultInterval={10000}
          />
        </div>
      </div>
    </>
  );
}

export default SlideShowPage;
