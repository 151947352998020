import React from "react";
import { Helmet } from "react-helmet";

import { NavigationCopy } from "../state/navigation_copy";
import { spanishLanguageMode, isMeAfterWe } from "../utils/config";

function OpenGraphMetaData({ title, image, description, isArticle }) {
  let defaultImage =
    "https://amh-slideshow-content.sfo2.cdn.digitaloceanspaces.com/default_share_image.png";
  if (spanishLanguageMode()) {
    defaultImage =
      "https://amh-slideshow-content.sfo2.cdn.digitaloceanspaces.com/default_share_image_es.png";
  }

  if (isMeAfterWe()) {
    defaultImage =
      "https://amh-slideshow-content.sfo2.cdn.digitaloceanspaces.com/maw_default_share_image.png";
  }

  const shareTitle = title || NavigationCopy.publicationTitle;
  const shareImage = image || defaultImage;
  return (
    <Helmet>
      <title>{NavigationCopy.publicationTitle}</title>
      <meta property="og:title" content={shareTitle} />
      <meta property="og:image" content={shareImage} />
      <meta property="og:image:alt" content={shareTitle} />
      <meta property="og:image:width" content="1000" />
      <meta property="og:image:height" content="1000" />
      {isArticle ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
    </Helmet>
  );
}

export default OpenGraphMetaData;
