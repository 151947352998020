import React from 'react'
import { Link } from 'react-router-dom'

function Item({ item, itemIndex }) {
  if (typeof itemIndex === 'undefined') {
    throw new Error('Item attempted to render without an index. Please ensure you are providing an itemIndex');
  }

  const backgroundPatternFile = '/backgrounds/' + ((itemIndex % 5) + 1).toString() + '.png'

  return (
    <Link to={ item.href }>
      <div className="item card clickable-card">
        <div className='card-banner'>
          <img src={backgroundPatternFile} alt='branded pattern' className='card-banner'></img>
        </div>
        <div className="card-content">
          <h2>{item.title}</h2>
          <p>{item.summary}</p>
        </div>
      </div>
    </Link>
  )

}

export default Item
