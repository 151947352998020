import React from "react";
import { spanishLanguageMode, isMeAfterWe } from "../utils/config";

function LanguageSpecificFooter() {
  if (spanishLanguageMode()) {
    return (
      <footer className="footer powered-by has-text-centered">
        <p>
          <a href="https://coronavirus.allmentalhealth.org">
            Prefer to read this in English?
          </a>
        </p>
        <p>
          Presentado por
          <a href="https://allmentalhealth.org"> “All Mental Health”</a> una
          organización 501c3.
        </p>
        <p>
          <a href="https://www.nfggive.com/guidestar/83-1611668">Done </a>
          para apoyar nuestra misión.
        </p>
      </footer>
    );
  }

  return (
    <footer className="footer powered-by has-text-centered">
      <p>
        {isMeAfterWe() ? null : (
          <a href="https://coronavirus-es.allmentalhealth.org">
            ¿Prefiere leer esto en español?
          </a>
        )}
      </p>
      <p>
        Brought to you by
        <a href="https://allmentalhealth.org"> All Mental Health</a> a
        registered 501(c)(3) organization.
      </p>
      <p>
        <a href="https://www.nfggive.com/guidestar/83-1611668">Donate </a> to
        support our mission.
      </p>
    </footer>
  );
}

export default LanguageSpecificFooter;
